<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Case Study Name"
          :helpText="'Provide a name of the case study. Please make sure that it is unique across the system'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="2"
          v-model="form.summary"
          :rules="descriptionRules"
          label="Summary"
          :helpText="'Please provide a summary in a few sentences to describe case study. This summary will be used as a help text.'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.web_url"
          :rules="linkRules"
          label="Web Link"
          :helpText="'Please provide a Link to the product if such exists.'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.brief"
          :rules="descriptionRules"
          label="Brief"
          :helpText="'Please provide a brief of this case study to make it more recognizable'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.what_has_been_done"
          label="What has been done"
          :helpText="'Please provide an information on what has been done during the case study execution'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.marketing_description"
          label="Marketing Description"
          :helpText="'This description should help non-technical employees and peoples understand what this case study means. This description may be used on the website or during document/presentations generation'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <FormSection
          class="mt-0"
          underline
          right
          :label="'Tags'"
          :icon="'mdi-tag-multiple-outline'"
          :actions="tagActions"
          :editable="!!tagActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>

        <SelectorKnowledgeBaseTagsVue
          dark
          autoload
          label="Tags"
          helpText="This is an actual Service Status"
          return-object
          v-model="form.tags"
          multiple
        >
        </SelectorKnowledgeBaseTagsVue>

        <FormSection
          class="mt-0"
          underline
          right
          :label="'Services'"
          :icon="'mdi-briefcase'"
          :actions="serviceActions"
          :editable="!!serviceActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>
        <SelectorServices
          dark
          autoload
          label="Services"
          helpText="You can select a set of services that have been done or are related to this case study"
          return-object
          v-model="form.services"
          multiple
        ></SelectorServices>

        <FormSection
          class="mt-0"
          underline
          right
          :label="'Deliverables'"
          :icon="'mdi-file-document-multiple-outline'"
          :actions="deliverableActions"
          :editable="!!deliverableActions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>

        <SelectorDeliverables
          dark
          autoload
          label="Deliverables"
          helpText="You can select a set of deliverables that have been produced during this case study"
          return-object
          v-model="form.deliverables"
          multiple
        ></SelectorDeliverables>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import { TAGS_BUTTONS } from "../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import {
  DELIVERABLE_BUTTONS,
  SERVICE_BUTTONS,
} from "../../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../../helpers/permissions.helper";
import SelectorDeliverables from "../../../../atoms/common/autocompletes/SelectorDeliverables.vue";
import SelectorKnowledgeBaseTagsVue from "../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import SelectorServices from "../../../../atoms/common/autocompletes/SelectorServices.vue";
import FormSection from "../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Case Study Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
    deliverableActions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.LIST, DELIVERABLE_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
    serviceActions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.LIST, SERVICE_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
    SelectorServices,
    SelectorDeliverables,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>